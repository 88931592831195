.App {
  overflow: hidden;
}

/* ----------------------------------------------------------------------------------------------------------------*/
/* Extra small + small devices (phones, 600px and down) */
/* ----------------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 768px) {

  /* Options bar-related CSS */
  .Options {
    display: flex;
    justify-content: flex-start;
    background-color: #ECE9DA;
  }

  .Options-Switch {
    color: #334866;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    padding: .5rem;
  }

  .Options-Switch:hover {
    color: #0056b3;
  }

  .Options-ExportCSV {
    color: #334866;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    padding: .5rem;
  }

  .Options-ExportCSV:hover {
    text-decoration: none;
  }

  /* Characters page-related CSS */
  .Chars-Title {
    padding-left: .5rem;
    padding-top: .5rem;
    margin-bottom: 0;
    font-size: 1.25rem;
  }

  .Chars-CharRow {
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: .5rem;
    margin: .5rem;
    border-width: .25rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    background-color: #334866;
    width: 96vw;
  }

  .Chars-CharBox {
    display: flex;
    width: 100%;
  }

  .Chars-Char {
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-width: .25rem;
    background-color: #ECE9DA;
    color: #334866;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transition: transform 0.25s ease-out;
  }

  .Chars-CompBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 2px dotted #ECE9DA;
    border-radius: 1rem;
  }

  .Chars-WordBox {
    display: flex;
    width: 100%;
    margin-left: 1rem;
  }

  .Chars-Word {
    display: flex;
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-width: .25rem;
    background-color: #ECE9DA;
    color: #334866;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transition: transform 0.25s ease-out;
  }

  .CharsGrid {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
  }

  /* Components page-related CSS */
  .Comps-Comp {
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-width: .25rem;
    background-color: #ECE9DA;
    color: #334866;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transition: transform 0.25s ease-out;
  }

  .Comps-Comp:hover {
    transform: scale(1.05);
    text-decoration: none;
  }
}

/* ----------------------------------------------------------------------------------------------------------------*/
/* Medium + large devices (landscape tablets, 768px and up) */
/* ----------------------------------------------------------------------------------------------------------------*/
@media only screen and (min-width: 768px) {

  /* Options bar-related CSS */
  .Options {
    display: flex;
    justify-content: flex-start;
    background-color: #ECE9DA;
  }

  .Options-Switch {
    color: #334866;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    padding: .5rem;
  }

  .Options-Switch:hover {
    color: #0056b3;
  }

  .Options-ExportCSV {
    color: #334866;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    padding: .5rem;
  }

  .Options-ExportCSV:hover {
    text-decoration: none;
  }

  /* Characters page-related CSS */
  .Chars-Title {
    padding-left: .5rem;
    padding-top: .5rem;
    margin-bottom: 0;
    font-size: 1.25rem;
  }

  .Chars-CharRow {
    font-size: 1.25rem;
    display: flex;
    border-radius: .5rem;
    margin: .5rem;
    border-width: .25rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    background-color: #334866;
    width: 98vw;
  }

  .Chars-CharBox {
    display: flex;
    width: 10%;
  }

  .Chars-Char {
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-width: .25rem;
    background-color: #ECE9DA;
    color: #334866;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transition: transform 0.25s ease-out;
  }

  .Chars-Char:hover,
  :focus {
    transform: scale(1.05);
  }

  .Chars-CompBox {
    display: flex;
    width: 40%;
  }

  .Chars-WordBox {
    display: flex;
    width: 50%;
  }

  .Chars-Word {
    display: flex;
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-width: .25rem;
    background-color: #ECE9DA;
    color: #334866;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transition: transform 0.25s ease-out;
  }

  .Chars-Word:hover,
  :focus {
    transform: scale(1.05);
    text-decoration: none;
  }

  .CharsGrid {
    display: flex;
  }

  /* Components page-related CSS */
  .Comps-Comp {
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-width: .25rem;
    background-color: #ECE9DA;
    color: #334866;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transition: transform 0.25s ease-out;
  }

  .Comps-Comp:hover {
    transform: scale(1.05);
    text-decoration: none;
  }
}

/* ----------------------------------------------------------------------------------------------------------------*/
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* ----------------------------------------------------------------------------------------------------------------*/
@media only screen and (min-width: 1200px) {

  /* Options bar-related CSS */
  .Options {
    display: flex;
    justify-content: flex-start;
    background-color: #ECE9DA;
  }

  .Options-Switch {
    color: #334866;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    padding: .5rem;
  }

  .Options-Switch:hover {
    color: #0056b3;
  }

  .Options-ExportCSV {
    color: #334866;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    padding: .5rem;
  }

  .Options-ExportCSV:hover {
    text-decoration: none;
  }

  /* Characters page-related CSS */
  .Chars-Title {
    padding-left: .5rem;
    padding-top: .5rem;
    margin-bottom: 0;
    font-size: 1.25rem;
  }

  .Chars-CharRow {
    font-size: 1.25rem;
    display: flex;
    border-radius: .5rem;
    margin: .5rem;
    border-width: .25rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    background-color: #334866;
    width: 98vw;
  }

  .Chars-CharBox {
    display: flex;
    width: 10%;
  }

  .Chars-Char {
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-width: .25rem;
    background-color: #ECE9DA;
    color: #334866;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transition: transform 0.25s ease-out;
  }

  .Chars-Char:hover,
  :focus {
    transform: scale(1.05);
  }

  .Chars-CompBox {
    display: flex;
    width: 40%;
  }

  .Chars-WordBox {
    display: flex;
    width: 50%;
  }

  .Chars-Word {
    display: flex;
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-width: .25rem;
    background-color: #ECE9DA;
    color: #334866;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transition: transform 0.25s ease-out;
  }

  .Chars-Word:hover,
  :focus {
    transform: scale(1.05);
    text-decoration: none;
  }

  .CharsGrid {
    display: flex;
    flex-wrap: wrap;
  }

  /* Components page-related CSS */
  .Comps-Comp {
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-width: .25rem;
    background-color: #ECE9DA;
    color: #334866;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transition: transform 0.25s ease-out;
  }

  .Comps-Comp:hover {
    transform: scale(1.05);
    text-decoration: none;
  }
}